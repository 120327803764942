<div class="app-candidate-details-images" fxLayout="column">
    <div fxLayout="row-reverse"
         *ngIf="profileImages && profileImages.length"
    >
        <span
            class="btn-view-image"
            (click)="showImage = false"
            *ngIf="showImage"
        >
            {{ 'DIALOG.HIDE_PROFILE' | translate }}</span
        >
        <span
            class="btn-view-image"
            (click)="showImage = true"
            *ngIf="!showImage"
        >
            {{ 'DIALOG.VIEW_PROFILE' | translate }}</span
        >
    </div>
    <div fxLayout="row-reverse"
         *ngIf="!profileImages || profileImages.length === 0"
    >
        <span
            class="btn-view-image-disabled"
        >
            {{ 'DIALOG.VIEW_PROFILE' | translate }}</span
        >
    </div>

    <div
        *ngIf="profileImages && profileImages.length && showImage"
        class="image-viewer-container"
    >
        <p class="user-img-name">{{ profileImages[viewingIndex].name }}</p>
        <div style="position: relative">
            <angular-image-viewer
                [(index)]="viewingIndex"
                [src]="getImgList()"
                [config]="imageViewerConfig"
                (customImageEvent)="handleImageEvent($event)"
            >
            </angular-image-viewer>
        </div>
    </div>
</div>
