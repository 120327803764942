import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'app/core/services/base.service';
import * as moment from 'moment';
import {
    Campaign,
    CampaignSideBarMenuItem,
    CampaignState,
    Feedback,
    Round,
    StatusEnum,
    TestTypeEnum,
} from 'app/shared/models/campaign.model';
import { AuthService } from 'app/main/authentication/auth.service';
import { cloneDeep, get, isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class CampaignRequestService extends BaseService {
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService
    ) {
        super();
    }

    getById(id: number): Observable<any> {
        return this._httpClient.get<any>(
            this.getFullUrl(`companies/campaigns/${id}`)
        );
    }

    create(model: Campaign): Observable<any> {
        return this._httpClient.post<Campaign>(
            this.getFullUrl('companies/campaigns'),
            model
        );
    }

    update(campaign: any): Observable<any> {
        const model = cloneDeep(campaign);
        model.userAction = JSON.stringify(model.userAction);
        return this._httpClient.put<Campaign>(
            this.getFullUrl(`companies/campaigns/${model.id}`),
            model
        );
    }

    updateRound(
        campaignId: number,
        roundId: number,
        roundModel: any
    ): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(
                `companies/campaigns/${campaignId}/rounds/${roundId}`
            ),
            roundModel
        );
    }

    delete(id: number): Observable<any> {
        return this._httpClient.delete(
            this.getFullUrl(`companies/campaigns/${id}`)
        );
    }

    cancel(id: number): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/cancel`),
            {}
        );
    }

    submit(id: number): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/submit`),
            {}
        );
    }

    ship(id: number): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/ship`),
            {}
        );
    }

    approve(id: number): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/approve`),
            {}
        );
    }

    reject(id: number, feedback: Feedback): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/reject`),
            feedback
        );
    }

    createRound(id: number, round: Round): Observable<any> {
        const savingRound = JSON.parse(JSON.stringify(round));
        savingRound.candidateGuide.place = JSON.stringify(
            savingRound.candidateGuide.place
        );
        return this._httpClient.post(
            this.getFullUrl(`companies/campaigns/${id}/rounds`),
            savingRound
        );
    }

    editRound(
        id: number,
        round: Round,
        notificationContents: string[]
    ): Observable<any> {
        const savingRound = JSON.parse(JSON.stringify(round));
        savingRound.candidateGuide.place = JSON.stringify(
            savingRound.candidateGuide.place
        );
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/rounds/${round.id}`),
            { ...savingRound, notificationContents }
        );
    }

    deleteRound(id: number, roundId: number): Observable<any> {
        return this._httpClient.delete(
            this.getFullUrl(`companies/campaigns/${id}/rounds/${roundId}`)
        );
    }

    submitInterviewResult(
        roundId: any,
        candidateId: any,
        data: any
    ): Observable<any> {
        return this._httpClient.post(
            this.getFullUrl(
                `companies/interview?roundId=${roundId}&candidateId=${candidateId}`
            ),
            data
        );
    }

    getCandidates(id: number): Observable<any> {
        return this._httpClient.get<any>(
            this.getFullUrl(`companies/campaigns/${id}/candidates`)
        );
    }

    getCandidateResults(id: number): Observable<any> {
        return this._httpClient.get<any>(
            this.getFullUrl(`companies/rounds/${id}/candidates`)
        );
    }

    getCandidateResultsStats(id: number): Observable<any> {
        return this._httpClient.get<any>(
            this.getFullUrl(`companies/rounds/${id}/candidates/stats`)
        );
    }

    getCandidateResultsByPage(
        id: number,
        page: number,
        searchKey = ''
    ): Observable<any> {
        const params = new HttpParams()
            .set('page', page + '')
            .set('size', '20')
            .set('name', searchKey);

        return this._httpClient.get<any>(
            this.getFullUrl(`companies/rounds/${id}/candidates`),
            {
                params,
            }
        );
    }

    getFinalResults(id: number): Observable<any> {
        return this._httpClient.get<any>('api/finalresults');
    }

    getActivationKeys(): Observable<any> {
        return this._httpClient.get<any>('api/activationKeys');
    }

    approveResult(id: number): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/nextround`),
            {}
        );
    }

    evaluate(id: number, candidates: any): Observable<any> {
        return this._httpClient.put(
            this.getFullUrl(`companies/campaigns/${id}/evaluate`),
            candidates
        );
    }

    getCampaignSidebarMenus({ rounds = [] }): CampaignSideBarMenuItem[] {
        const roundMenu = rounds.map((round, index) => ({
            step: index + 1,
            title:
                round.type === TestTypeEnum.TEST
                    ? 'CAMPAIGN.ROUND.TEST'
                    : 'CAMPAIGN.ROUND.INTERVIEW',
            data: {
                menuAction: 'round_select',
                ...round,
            },
        }));
        return [
            {
                step: 1,
                title: 'CAMPAIGN.STEP.STEP_1',
            },
            {
                step: 2,
                title: 'CAMPAIGN.STEP.STEP_2',
            },
            {
                step: 3,
                title: 'CAMPAIGN.STEP.STEP_3',
                hasSubmenu: true,
                subMenu: roundMenu,
            },
            {
                step: 4,
                title: 'CAMPAIGN.STEP.STEP_4',
                last: true,
            },
        ] as CampaignSideBarMenuItem[];
    }

    getCampaignState(campaign: any): CampaignState {
        const currentUser = this._authService.getCurrentUser();
        const isOwner = currentUser.identity === campaign.ownerId;
        const isReviewer = currentUser.identity === campaign.reviewerId;
        const isApprover = currentUser.identity === campaign.approverId;
        const { status, resultStatus } = campaign;
        const { to } = campaign;

        if (
            status !== StatusEnum.NOT_READY &&
            moment(new Date()).isAfter(moment(new Date(to)))
        ) {
            return status === StatusEnum.CANCELED
                ? CampaignState.CLOSED
                : CampaignState.EXPIRED;
        }

        if (status === StatusEnum.CANCELED) {
            return CampaignState.CLOSED;
        } else if (status === StatusEnum.NOT_READY && isOwner) {
            return CampaignState.EDITING;
        } else if (status === StatusEnum.REJECTED) {
            return CampaignState.REJECTED;
        } else if (status === StatusEnum.PENDING_REVIEW && isReviewer) {
            return CampaignState.REVIEWING;
        } else if (status === StatusEnum.REVIEWED && isApprover) {
            return CampaignState.REVIEWING;
        } else if (status === StatusEnum.APPROVED) {
            if (
                isEmpty(campaign.rounds) ||
                isEmpty(campaign.rounds.filter((c) => c.active))
            ) {
                if (!isEmpty(resultStatus)) {
                    switch (resultStatus) {
                        case StatusEnum.REJECTED:
                            return CampaignState.RESULT_REJECTED;
                        case StatusEnum.PENDING_REVIEW:
                        case StatusEnum.REVIEWED:
                            return CampaignState.RESULT_REVIEWING;
                        case StatusEnum.APPROVED:
                            return CampaignState.NOTIFYING;
                        case StatusEnum.NOTIFIED:
                            return CampaignState.ACCEPTING_JOB;
                        default:
                            return CampaignState.EVALUATING;
                    }
                }
                return CampaignState.EVALUATING;
            } else {
                const activeRound = campaign.rounds.filter((c) => c.active)[0];
                const activeRoundIndex = campaign.rounds.findIndex(
                    (c) => c.active
                );
                const activeRoundType = activeRound.type;

                if (activeRoundIndex > 0) {
                    return activeRoundType === TestTypeEnum.TEST
                        ? CampaignState.TESTING
                        : CampaignState.INTERVIEWING;
                }

                if (activeRoundType === TestTypeEnum.TEST) {
                    if (
                        moment(activeRound.activeFrom).isAfter(
                            moment(new Date())
                        )
                    ) {
                        return CampaignState.FILTERING;
                    } else {
                        return CampaignState.TESTING;
                    }
                } else {
                    const startTime =
                        get(activeRound, 'candidateGuide.startTime', 0) || 0;

                    if (
                        startTime === 0 ||
                        moment(startTime).isAfter(moment(new Date()))
                    ) {
                        return CampaignState.FILTERING;
                    } else {
                        return CampaignState.INTERVIEWING;
                    }
                }
            }
        } else {
            return CampaignState.VIEWING;
        }
    }
}
