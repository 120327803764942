import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { LoginService } from './login.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ACCOUNT_ROLE } from '../../account/account.model';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    showError: boolean;
    version: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _loginService: LoginService,
        private router: Router,
        private authService: AuthService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.showError = false;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
        this.version = environment.version;
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            remember: [false],
        });

        this.loadAccount();
    }

    onSubmit(data): void {
        this._loginService
            .login(data.email, data.password)
            .then((response) => {
                this.authService.storeUser(response);
                if (response.isAdmin) {
                    this._fuseNavigationService.setCurrentNavigation(
                        'adminNav'
                    );
                } else if (response.role && response.role === ACCOUNT_ROLE.SUPER_ADMIN) {
                    this._fuseNavigationService.setCurrentNavigation(
                        'superAdminNav'
                    );
                } else {
                    this._fuseNavigationService.setCurrentNavigation(
                        'staffNav'
                    );
                }
                const { role = '' } = response;
                if (role === ACCOUNT_ROLE.BCT_ADMIN) {
                    this.router.navigate(['/report']);
                } else {
                    this.router.navigate(['/dashboards']);
                }
                this.checkAndSaveAccount();
            })
            .catch((err) => {
                console.log(err);
                this.showError = true;
            });
    }

    loadAccount = () => {
        const email = localStorage.getItem('email');
        if (email && email.length) {
            const password = localStorage.getItem('password');
            this.loginForm.get('email').setValue(email);
            this.loginForm.get('password').setValue(password);
            this.loginForm.get('remember').setValue(true);
        }
    };

    checkAndSaveAccount = () => {
        if (this.loginForm.get('remember').value) {
            localStorage.setItem('email', this.loginForm.get('email').value);
            localStorage.setItem(
                'password',
                this.loginForm.get('password').value
            );
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }
    };

    goToHomePage() {
        location.assign('https://home.viecnhamay.vn/');
    }
}
