<div class="page-layout simple fullwidth">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h2>{{ 'NAV.ACCOUNT.COMPANY.TITLE' | translate }}</h2>
        </div>
    </div>

    <app-basic-information
        [company]="company"
    ></app-basic-information>

    <div class="loading-container" *ngIf="isProcessing">
        <mat-spinner></mat-spinner>
    </div>
</div>
