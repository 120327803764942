<div id="poster-adding-form" class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <h2>Thêm Poster</h2>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="center poster-adding-list-panel">
        <div class="panel-body p-8" fusePerfectScrollbar>
            <app-poster-list
                [isMulti]='true'
                [posterList]="posterList"
                (selectedPosterEmitter)="selectPoster($event)"
            ></app-poster-list>
            <div class="clearfix"></div>
        </div>
    </div>

    <div
        mat-dialog-actions
        class="m-0"
        fxLayout="row"
        fxLayoutAlign="end center"
    >
        <button
            mat-raised-button
            color="primary"
            class="no-button"
            (click)="onNoClick()"
        >
            Đóng
        </button>
        <button
            mat-raised-button
            color="accent"
            class="yes-button"
            [mat-dialog-close]="selectedPoster"
        >
            Thêm Poster
        </button>
    </div>
</div>
