import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
    CellClickedEvent,
    ColDef,
    GridApi,
    GridReadyEvent,
} from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignResultService } from '../campaign-result.service';
import {
    CampaignState,
    CandidateFinalResult,
    RoundResult,
    TestTypeEnum,
} from '../../../../shared/models/campaign.model';
import { CampaignResultCellRendererComponent } from './campaign-result-cell-renderer.component';
import { get, isEmpty } from 'lodash';
import CampaignUtils from '../../shared/campaign-utils';
import { CampaignService } from '../../campaign.service';

const averageCol = {
    field: 'average',
    cellRenderer: CampaignResultCellRendererComponent,
    headerName: 'Điểm trung bình (%)',
    width: 140,
    cellClass: 'right-cell-class',
};

const fullNameCol = {
    field: 'fullName',
    cellRenderer: CampaignResultCellRendererComponent,
    getQuickFilterText: ({ data }) => get(data, 'candidate.fullName') || '',
    headerName: 'Họ tên',
    width: 160,
    cellClass: 'center-cell-class',
    comparator: (valueA, valueB, nodeA, nodeB) => {
        const text1 = get(nodeA, 'data.candidate.fullName', '');
        const text2 = get(nodeB, 'data.candidate.fullName', '');
        return text1.localeCompare(text2);
    },
};

const phoneCol = {
    field: 'phone',
    cellRenderer: CampaignResultCellRendererComponent,
    headerName: 'Số điện thoại',
    width: 100,
    cellClass: 'center-cell-class',
    comparator: (valueA, valueB, nodeA, nodeB) => {
        const text1 = get(nodeA, 'data.candidate.phone', '');
        const text2 = get(nodeB, 'data.candidate.phone', '');
        return text1.localeCompare(text2);
    },
};

const checkBoxCol = CampaignUtils.checkBoxCol;

const initialColDef: ColDef[] = [
    {
        valueGetter: 'node.rowIndex + 1',
        headerName: '#',
        width: 40,
        cellStyle: { 'text-align': 'center' },
        cellClass: 'center-cell-class',
        sortable: false,
    },
    {
        field: 'avatar',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Hình thẻ',
        width: 72,
        autoHeight: true,
        cellStyle: { 'text-align': 'center' },
        sortable: false,
    },
    fullNameCol,
    {
        field: 'confirm',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Xác nhận đi làm',
        width: 114,
        cellStyle: { 'text-align': 'center' },
    },
    {
        field: 'age',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Tuổi',
        width: 42,
        cellClass: 'right-cell-class',
        comparator: (valueA, valueB, nodeA, nodeB) => {
            const val1 = get(nodeA, 'data.candidate.birthday', 0);
            const val2 = get(nodeB, 'data.candidate.birthday', 0);
            return val1 - val2;
        },
    },
    {
        field: 'health',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Sức khỏe',
        width: 120,
        cellClass: 'center-cell-class',
        comparator: (valueA, valueB, nodeA, nodeB) => {
            const text1 = get(
                nodeA,
                'data.candidate.healthCertificate.type',
                ''
            );
            const text2 = get(
                nodeB,
                'data.candidate.healthCertificate.type',
                ''
            );
            return text1.localeCompare(text2);
        },
    },
    {
        field: 'exp',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Kinh nghiệm (năm)',
        width: 120,
        cellClass: 'right-cell-class',
        comparator: (valueA, valueB, nodeA, nodeB) => {
            const exp1 = get(nodeA, 'data.candidate.totalExperienceMonth', 0);
            const exp2 = get(nodeB, 'data.candidate.totalExperienceMonth', 0);
            return exp1 - exp2;
        },
    },
    averageCol,
    {
        field: 'recruitmentType',
        cellRenderer: CampaignResultCellRendererComponent,
        headerName: 'Đánh giá',
        minWidth: 150,
        flex: 1,
        cellClass: 'center-cell-class',
    },
];

@Component({
    selector: 'app-campaign-result-table',
    templateUrl: './campaign-result-table.component.html',
    styleUrls: ['./campaign-result-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignResultTableComponent implements OnInit, OnDestroy {
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    @Input() loadingIndicator: boolean;
    @Input() tableStyle = {
        height: 'calc(100vh - 30rem)',
        width: '100%',
    };
    @Output() onRefresh: EventEmitter<any> = new EventEmitter();
    columnDefs: ColDef[] = [];
    defaultColDef = CampaignUtils.defaultColDef;
    rowData$!: Observable<any[]>;
    maxScrollTop = 0;
    timeoutId = null;
    candidates: CandidateFinalResult[];
    roundResults: RoundResult[];
    canSelectCandidate = false;
    private unsubscribeAll: Subject<any>;
    private gridApi!: GridApi;

    constructor(
        private campaignResultService: CampaignResultService,
        private campaignService: CampaignService
    ) {
        this.unsubscribeAll = new Subject();
        this.rowData$ = new Observable();
    }

    ngOnInit(): void {
        this.candidates = this.campaignResultService.candidates;
        if (!isEmpty(this.candidates)) {
            this.setUpTable(this.candidates);
        }

        this.campaignResultService.onPaginatedDataChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((candidates) => {
                this.candidates = [...this.candidates, ...candidates];
                if (this.campaignResultService.nextPage > 1) {
                    this.addRoundResultIntoColumnTemplates(candidates);
                    this.gridApi.applyTransaction({ add: candidates });
                } else {
                    this.setUpTable(candidates);
                }
            });

        this.campaignResultService.onFilterChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.campaignResultService.deselectCandidates();
                this.refreshTable();
            });
    }

    onScrollEnd(event) {
        const { top = -1 } = event;

        if (top <= 520 || top > this.maxScrollTop + 520) {
            this.maxScrollTop = top <= 520 ? 520 : top;

            this.fetchMoreData();
        }
    }

    fetchMoreData() {
        this.campaignResultService.getMoreResult()?.then(() => {});
    }

    ngOnDestroy(): void {
        this.campaignResultService.nextPage = 0;
        this.campaignResultService.fetchedAll = false;
        this.campaignResultService.currentTableApi = null;
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onFilterTextBoxChanged(event) {
        const value = get(event, 'target.value') || '';

        const callSearch = () => {
            console.log(value);
            this.campaignResultService.onSearchTextChanged.next(value);
        };
        const triggerCall = () => {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(callSearch, 500);
        };

        triggerCall();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.campaignResultService.currentTableApi = params.api;
    }

    onCellClicked(e: CellClickedEvent): void {}

    onRowSelected(e): void {
        const selectedRows = this.gridApi.getSelectedRows();
        this.campaignResultService.selectCandidates(selectedRows);
    }

    onLoaded(e) {
        if (!isEmpty(this.gridApi)) {
            const verticalPixelRange = this.gridApi.getVerticalPixelRange();
            const { bottom = 0 } = verticalPixelRange;
            if (bottom > this.maxScrollTop + 520) {
                this.maxScrollTop = bottom;
                setTimeout(() => {
                    this.fetchMoreData();
                }, 1000);
            }
        }
    }

    reloadResultTable() {
        this.onRefresh.emit();
    }

    private setUpTable(candidates) {
        this.columnDefs = [...initialColDef];
        this.columnDefs.find((c) => c.field === 'fullName').onCellClicked = (
            event
        ) =>
            this.campaignService.showCandidateDetails(
                CampaignUtils.convertToTestResultTableRow({
                    originalProfile: event.data.originalProfile,
                    ...event.data.candidate,
                })
            );
        this.setUpCheckBox();
        this.addPhoneNumberIntoColumnTemplates();
        this.addRoundResultIntoColumnTemplates(candidates);
        if (!isEmpty(this.gridApi)) {
            this.gridApi.setColumnDefs(this.columnDefs);
        }
        this.rowData$ = new Observable((observer) => {
            observer.next(candidates);
        });
        this.refreshTable();
    }

    private refreshTable() {
        if (!isEmpty(this.gridApi)) {
            this.gridApi.refreshCells();
            this.gridApi.deselectAll();
        }
    }

    private setUpCheckBox() {
        this.canSelectCandidate = this.isAbleToSelectCandidate();
        if (this.canSelectCandidate) {
            this.columnDefs.unshift(checkBoxCol);
        }
    }

    private addRoundResultIntoColumnTemplates(
        candidates: CandidateFinalResult[]
    ): void {
        if (candidates.length) {
            this.roundResults = candidates[0].roundResults;
            const averageIndex = this.columnDefs.indexOf(averageCol);
            this.columnDefs.splice(
                averageIndex,
                0,
                ...candidates[0].roundResults.map((round, index) => ({
                    field: `round_${index}`,
                    headerName: `${index + 1} ${
                        round.type === TestTypeEnum.INTERVIEW
                            ? 'Phỏng vấn'
                            : 'Thi kiểm tra'
                    } (%)`,
                    cellRenderer: CampaignResultCellRendererComponent,
                    cellRendererParams: {
                        roundIndex: index,
                    },
                    width: 130,
                    cellClass: 'right-cell-class',
                    comparator: (valueA, valueB, nodeA, nodeB) => {
                        const val1 = get(
                            nodeA,
                            `data.roundResults[${index}].score`,
                            0
                        );
                        const val2 = get(
                            nodeB,
                            `data.roundResults[${index}].score`,
                            0
                        );
                        return val1 - val2;
                    },
                }))
            );
        }
    }

    private addPhoneNumberIntoColumnTemplates() {
        const resultStatus = this.campaignResultService.currentResultStatus;
        if (
            [CampaignState.NOTIFIED, CampaignState.FINISHED].includes(
                resultStatus as CampaignState
            )
        ) {
            const nameIndex = this.columnDefs.indexOf(fullNameCol);
            this.columnDefs.splice(nameIndex + 2, 0, phoneCol);
        }
    }

    private isAbleToSelectCandidate() {
        return this.campaignResultService.isManagingResult();
    }
}
