import { FuseUtils } from '@fuse/utils';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment';

export class Company {
    id: number;
    logo: string;
    prefix: string;
    fullName: string;
    taxCode: string;
    taxCodeIssueDate: Moment;
    taxCodeIssuer: string;
    description: string;
    images: string[];
    posters: CompanyPoster[];
    branches: CompanyBranch[];
    relatedJobs: string[];
    welfare: CompanyWelfare[];
    path: string;
    address: CompanyAddress;
    allowEdit: boolean;
    allowPostCampaign: boolean;
    companyRegistrationImages: any[];

    constructor(company?) {
        {
            if (!isEmpty(company)) {
                this.id = company.id || FuseUtils.generateGUID();
                this.logo = company.logo || '';
                this.prefix = company.prefix || '';
                this.fullName = company.fullName || '';
                this.images = company.images || [];
                this.posters = company.posters || [];
                this.branches = company.branches || [];
                this.relatedJobs = company.relatedJobs || [];
                this.welfare = company.welfare || [];
                this.path = company.path || '';
                this.address = company.address || {};
                this.taxCode = company.taxCode || '';
                this.taxCodeIssueDate =
                    moment(company.taxCodeIssueDate) || null;
                this.taxCodeIssuer = company.taxCodeIssuer || '';
                this.allowEdit = company.allowEdit || false;
                this.allowPostCampaign = company.allowPostCampaign || false;
                this.companyRegistrationImages = company.companyRegistrationImages || [];
            } else {
                this.welfare = [];
                this.posters = [];
            }
        }
    }
}

export class CompanyAddress {
    address: string;
    townId: number;
    town: string;
    industryParkId: number;
    industryPark: string;
    districtId: number;
    district: string;
    provinceId: number;
    province: string;
    mapLink?: string;
}

export class CompanyBranch {
    id: number;
    address: CompanyAddress;
    headQuarter: boolean;
}

export class CompanyWelfare {
    id: number;
    category: string;
    valueType: string;
    status: string;
    from: number;
    to: number;
    note: string;
    posters?: CompanyPoster[];
    size: number;

    constructor(welfare: any) {
        if (!isEmpty(welfare)) {
            this.id = welfare.id;
            this.category = welfare.category;
            this.valueType = welfare.valueType;
            this.posters = welfare.posters;
            this.size = welfare.size;
        }
    }
}

export class CompanyPoster {
    id: number;
    type: string; //'vertical-horizontal'
    content: string;
    contentPosition: string; //'top-bottom'
    bgType: string; //'color-pattern-image'
    bgValue: string; //'base64'
}

export class JobCategory {
    id: number;
    createdBy: string;
    creationDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    content: string;
}
