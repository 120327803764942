<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button
                mat-icon-button
                (click)="matDialogRef.close()"
                aria-label="Close dialog"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0">

        <form [formGroup]="accountForm">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Anh/Chị</mat-label>
                    <mat-select formControlName="gender" required>
                        <mat-option class='option' value='MALE'>Anh</mat-option>
                        <mat-option class='option' value='FEMALE'>Chị</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="!isEditingAdminAccount" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Họ và tên đệm</mat-label>
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <input
                        name="lastName"
                        formControlName="lastName"
                        matInput
                        required
                    />
                </mat-form-field>
            </div>

            <div *ngIf="!isEditingAdminAccount" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Tên</mat-label>
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <input
                        name="firstName"
                        formControlName="firstName"
                        matInput
                        required
                    />
                </mat-form-field>
            </div>

            <div *ngIf="!isEditingAdminAccount" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Email</mat-label>
                    <input
                        name="email"
                        formControlName="email"
                        matInput
                        required
                    />
                </mat-form-field>
            </div>

            <div *ngIf="!isEditingAdminAccount && !isSelfEditing" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Phân quyền</mat-label>
                    <mat-select name="role" formControlName="role" required>
                        <mat-option class="option" value="HR"
                            >Chuyên viên nhân sự</mat-option
                        >
                        <mat-option class="option" value="STAFF"
                            >Thành viên</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="isSelfEditing && isEditingAdminAccount" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Họ và tên Quản trị viên</mat-label>
                    <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                    >
                    <input
                        name="firstName"
                        formControlName="firstName"
                        matInput
                        required
                    />
                </mat-form-field>
            </div>

            <div *ngIf="isSelfEditing && isEditingAdminAccount" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Điện thoại liên lạc</mat-label>
                    <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                    >
                    <input
                        name="phone"
                        formControlName="phone"
                        matInput
                        required
                    />
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button
            *ngIf="action === 'new'"
            mat-raised-button
            color="accent"
            (click)="matDialogRef.close(accountForm)"
            [disabled]="accountForm.invalid"
            aria-label="SAVE"
        >
            Thêm
        </button>

        <button
            *ngIf="action === 'edit' || action === 'self-edit'"
            mat-raised-button
            color="accent"
            (click)="matDialogRef.close(['save', accountForm])"
            [disabled]="accountForm.invalid"
            aria-label="SAVE"
        >
            Lưu
        </button>
    </div>
</div>
