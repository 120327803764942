<div
    *ngIf="!isLoading"
    mat-dialog-content
    fxLayout="column"
    class="candidate-details-dialog"
>
    <div class="top-btn-container">
        <span (click)="dialogRef.close(needRemove)">
            <mat-icon class="btn-close">close</mat-icon>
        </span>
    </div>
    <div fxLayout="row" fxLayoutGap="1rem" class="mt-10">
        <div>
            <img
                class="avatar-img"
                src="{{ candidate.avatar }}"
                alt="{{ candidate.fullName }}"
            />
        </div>
        <table>
            <tr>
                <td class="cell-label">Họ tên</td>
                <td class="cell-value">{{ candidate.fullName }}</td>
                <td class="cell-label">Nơi ở</td>
                <td class="cell-value">{{ candidate.currentAddress }}</td>
            </tr>
            <tr>
                <td class="cell-label">Giới tính</td>
                <td class="cell-value">{{ candidate.gender }}</td>
                <td class="cell-label">Hộ khẩu</td>
                <td class="cell-value">{{ candidate.address }}</td>
            </tr>
            <tr>
                <td class="cell-label">Tuổi</td>
                <td class="cell-value">{{ candidate.age }}</td>
                <td class="cell-label">Sức khỏe</td>
                <td class="cell-value">{{ candidate.health }}</td>
            </tr>
            <tr>
                <td class="cell-label">Bằng cấp</td>
                <td class="cell-value">
                    <p *ngFor="let cer of candidate.certificateStrings">
                        {{ cer }}
                    </p>
                </td>
                <td class="cell-label">Kinh nghiệm</td>
                <td class="cell-value">
                    <p *ngFor="let exp of candidate.experienceStrings">
                        {{ exp }}
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center">
        <mat-label> Duyệt hồ sơ &nbsp;</mat-label>
        <mat-slide-toggle
            [(ngModel)]="approved"
            (change)="handleApprove($event)"
        ></mat-slide-toggle>
    </div>
    <app-candidate-details-images
        [profileImages]="profileImages"
        [isLoading]="isLoading"
        [autoShow]="true"
    ></app-candidate-details-images>
</div>
<mat-progress-spinner *ngIf="isLoading" color="primary" mode="indeterminate">
</mat-progress-spinner>
