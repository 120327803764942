import { fuseAnimations } from '../../../../@fuse/animations';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, merge, Subject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { Company, CompanyWelfare } from '../company/company.model';
import { CompanyService } from '../company/company.service';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewEncapsulation,
    OnDestroy,
} from '@angular/core';
import { PosterAddingFormComponent } from '../shared/poster-adding-form/poster-adding-form.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { MatAccordion } from '@angular/material/expansion';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-welfare',
    templateUrl: './welfare.component.html',
    styleUrls: ['./welfare.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class WelfareComponent implements OnInit, OnDestroy {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild(MatSort)
    sort: MatSort;
    welfareList: any;
    welfareIndexMap: any;
    availableWelfareMap: any;
    loadingWelfareIds = [];

    dialogRef: any;

    posterList: any;
    posterMap: any;

    dataSource: FilesDataSource | null;

    displayedColumns = ['name', 'status'];
    radioValue: any;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    isSaving = false;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private companyService: CompanyService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private overlay: Overlay
    ) {
        this.posterList = [];
        this.posterMap = [];
        this.welfareList = [];
        this.welfareIndexMap = [];
        this.availableWelfareMap = [];
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.companyService, this.sort);
        this.radioValue = 0;

        this.companyService.availableWelfares.forEach((w) => {
            this.availableWelfareMap[w.category] = w;
        });

        this.companyService.onCompanyPostersChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((posters) => {
                this.posterList = posters;
                this.posterList.forEach((poster) => {
                    this.posterMap[poster.id] = poster;
                });
            });

        this.companyService.onCompanyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((c) => {
                this.posterMap = [];
                this.posterList = c.posters;
                this.posterList.forEach((poster) => {
                    this.posterMap[poster.id] = poster;
                });

                this.welfareList = this._filterWelfare(c.welfare);
                this.welfareList = this.welfareList.sort(
                    (w1, w2) => w1.sortOrder - w2.sortOrder
                );
                this.welfareList.forEach((welfare, index) => {
                    this.welfareIndexMap[welfare.id] = index;
                    const posterIDList = [];
                    if (welfare.posters && welfare.posters.length) {
                        welfare.posters = welfare.posters.filter(
                            (p) => p != null
                        );
                        welfare.posters.forEach((w) =>
                            posterIDList.push(w.id ? w.id : w)
                        );
                    }
                    welfare.posters = posterIDList;
                });
            });
    }

    ngOnDestroy() {
        this.accordion.closeAll();
        this._unsubscribeAll.complete();
    }

    loadWelfare(welfare: CompanyWelfare, event: boolean) {
        const { id = 0, posters = [] } = welfare;
        if (id === 0 || !event || posters.length > 0) {
            return;
        }
        this.loadingWelfareIds.push(id);
        this.companyService.getWelfareById(id).then(() => {
            this.loadingWelfareIds = this.loadingWelfareIds.filter(
                (_id) => _id != id
            );
        });
    }

    _filterWelfare(welfare) {
        const filteredWelfare = [];
        if (welfare && welfare.length) {
            welfare.forEach((w) => {
                if (this.availableWelfareMap[w.category]) {
                    w.valueType =
                        this.availableWelfareMap[w.category].valueType;
                    w.sortOrder = this.availableWelfareMap[w.category].id;
                    w.status = 'YES';
                    filteredWelfare.push(w);
                }
            });
        }
        return filteredWelfare;
    }

    generatePosterListById(posterIds: any) {
        if (!posterIds || !posterIds.length) {
            return [];
        }
        const _posterList = [];
        posterIds.forEach((id) => {
            _posterList.push(this.posterMap[id]);
        });
        return _posterList;
    }

    selectPoster(welfareId: any, poster: any) {
        // console.log("selected:", poster, "from welfareId:", welfareId);
    }

    deletePoster(welfareId: any, poster: any) {
        const welfareIndex = this.welfareIndexMap[welfareId];

        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
                scrollStrategy: this.overlay.scrollStrategies.noop(),
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            'Bạn có muốn xoá poster này?';

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.welfareList[welfareIndex].posters.splice(poster.index, 1);

                this.saveWelfare(this.welfareList[welfareIndex], () => {
                    this.welfareList[welfareIndex].size--;
                });
            }
            this.confirmDialogRef = null;
        });
    }

    onAddPosterBtnClick(welfareId: any): void {
        const welfareIndex = this.welfareIndexMap[welfareId];
        this.dialogRef = this._matDialog.open(PosterAddingFormComponent, {
            panelClass: 'poster-adding-form-dialog',
            data: {
                action: 'new',
            },
        });

        this.dialogRef.afterClosed().subscribe((result = []) => {
            if (isEmpty(result)) {
                return;
            }
            const oldPosterIds = this.welfareList[welfareIndex].posters;
            const newPosterIds = result.map((p) => p.id);

            this.welfareList[welfareIndex].posters = [
                ...oldPosterIds,
                ...newPosterIds,
            ];

            this.welfareList[welfareIndex].posters = this.welfareList[
                welfareIndex
            ].posters.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );

            this.saveWelfare(this.welfareList[welfareIndex], () => {
                this.welfareList[welfareIndex].size++;
            });
        });
    }

    saveWelfare(welfare, callback) {
        if (
            welfare &&
            welfare.valueType === 'RANGE' &&
            welfare.from > welfare.to
        ) {
            this._matSnackBar.open('Dữ liệu nhập vào sai', 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
            return;
        }

        this.isSaving = true;
        this.companyService
            .saveCompanyWelfare(welfare)
            .then(() => {
                callback && callback();
                this._matSnackBar.open('Thành công!!', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
            })
            .catch((err) => {
                console.log('Saving error: ', err);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}

export class FilesDataSource extends DataSource<any> {
    constructor(
        private companyService: CompanyService,
        private matSort: MatSort
    ) {
        super();
    }

    connect(): Observable<CompanyWelfare[]> {
        const displayDataChanges = [
            this.companyService.onCompanyChanged,
            this.matSort.sortChange,
        ];

        return merge(...displayDataChanges).pipe(
            map(() => this.sortData(this.companyService.company.welfare))
        );
    }

    sortData(data: CompanyWelfare[]): CompanyWelfare[] {
        if (!this.matSort.active || this.matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this.matSort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.category, b.category];
                    break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (
                (valueA < valueB ? -1 : 1) *
                (this.matSort.direction === 'asc' ? 1 : -1)
            );
        });
    }

    disconnect(): void {}
}
