import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Account, ACCOUNT_ROLE } from '../../account.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-account-form',
    templateUrl: './account-form.component.html',
    styleUrls: ['./account-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccountFormDialogComponent {
    action: string;
    account: Account;
    accountForm: FormGroup;
    dialogTitle: string;
    isEditingAdminAccount = false;
    isSelfEditing = false;

    constructor(
        public matDialogRef: MatDialogRef<AccountFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Chỉnh sửa tài khoản';
            this.account = _data.account;
        } else if (this.action === 'self-edit') {
            this.dialogTitle = 'Chỉnh sửa tài khoản';
            this.account = _data.account;
            this.checkEditingAccountRole();
        } else {
            this.dialogTitle = 'Thêm tài khoản';
            this.account = new Account({});
        }

        this.accountForm = this.createAccountForm();
    }

    createAccountForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.account.id],
            firstName: [this.account.firstName],
            lastName: [this.account.lastName],
            avatar: [this.account.avatar],
            staffCode: [this.account.staffCode],
            email: [this.account.email],
            phone: [this.account.phone],
            gender: [this.account.gender],
            activationKey: '',
            role: [this.account.role],
        });
    }

    private checkEditingAccountRole = () => {
        this.isSelfEditing = true;
        this.isEditingAdminAccount =
            this.account.role === ACCOUNT_ROLE.SUPER_ADMIN ||
            this.account.role === ACCOUNT_ROLE.ADMIN;
    };
}
