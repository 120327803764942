import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { userNavigation } from 'app/navigation/userNavigation';
import { superAdminNavigation } from 'app/navigation/superAdminNavigation';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './main/authentication/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private _authService: AuthService,
        public router: Router
    ) {
        // Add languages
        this._translateService.addLangs(['vi', 'en']);

        // Set the default language
        this._translateService.setDefaultLang('vi');

        // Use a language
        this._translateService.use('vi');

        this.matIconRegistry.addSvgIcon(
            `qr_code`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `../assets/icons/qr.svg`
            )
        );
        this._fuseNavigationService.register('adminNav', navigation);
        this._fuseNavigationService.register('staffNav', userNavigation);
        this._fuseNavigationService.register(
            'superAdminNav',
            superAdminNavigation
        );

        const user = this._authService.getCurrentUser();
        if (user) {
            if (user.isAdmin) {
                this.navigation = navigation;
                this._fuseNavigationService.setCurrentNavigation('adminNav');
            } else if (user.role && user.role === 'SUPER_ADMIN') {
                this.navigation = superAdminNavigation;
                this._fuseNavigationService.setCurrentNavigation(
                    'superAdminNav'
                );
            } else {
                this.navigation = userNavigation;
                this._fuseNavigationService.setCurrentNavigation('staffNav');
            }
        } else {
            if (!location.href.includes('/download') &&
                !location.href.includes('auth/register')) {
                this.router.navigate(['auth/login']).then(() => {});
            }
        }

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang("en");
            this._translateService.setDefaultLang("tr");
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                // this.fuseConfig.layout.footer.background = "fuse-white";
                // this.fuseConfig.layout.toolbar.customBackgroundColor = true;
                // this.fuseConfig.layout.toolbar.background = "fuse-white";

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
