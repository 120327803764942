import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Observable, Subject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { ActivationKeyService } from './activation-key.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-activation-key',
    templateUrl: './activation-key.component.html',
    styleUrls: ['./activation-key.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ActivationKeyComponent implements OnInit, OnDestroy {
    activationKeys: any;
    availableQuantity = 0;
    quantity;

    packageList = [
        {
            name: 'MAX50 (50 ứng viên)',
            value: 50,
        },
        {
            name: 'MAX100 (100 ứng viên)',
            value: 100,
        },
        {
            name: 'MAX150 (150 ứng viên)',
            value: 150,
        },
        {
            name: 'MAX350 (350 ứng viên)',
            value: 350,
        },
        {
            name: 'MAX650 (650 ứng viên)',
            value: 650,
        },
        {
            name: 'MAX1200 (1200 ứng viên)',
            value: 1200,
        },
        {
            name: 'UNLIMITED (không giới hạn ứng viên)',
            value: 9999999999,
        },
    ];

    dataSource: FilesDataSource | null;
    displayedColumns = [
        'id',
        'requester',
        'quantity',
        'activationDate',
        'expirationDate',
        'status',
    ];
    private unsubscribeAll: Subject<any>;
    constructor(
        private matSnackBar: MatSnackBar,
        private matDialog: MatDialog,
        private activationKeyService: ActivationKeyService
    ) {
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.activationKeyService);

        this.activationKeyService.getKeyHistory().then(() => {});

        this.activationKeyService.onActivationKeysChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((activationKeys) => {
                this.activationKeys = activationKeys;
                if (!isEmpty(activationKeys)) {
                    const { company } = activationKeys[0];
                    const { remainingInvitation = 0 } = company;
                    this.availableQuantity = remainingInvitation;
                }
            });

        this.activationKeyService.onFilterChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {});
    }

    createOrder() {
        if (this.quantity === undefined || this.quantity < 1) {
            this.matSnackBar.open('Vui lòng chọn gói!', 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
            return;
        }

        const confirmDialog = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        const selectedPackage = this.packageList.filter(
            (p) => p.value === this.quantity
        )[0];

        confirmDialog.componentInstance.confirmMessage = `Xác nhận gửi yêu cầu báo giá gói ${selectedPackage.name}?`;
        confirmDialog.componentInstance.noLabel = 'Xem lại';

        confirmDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.activationKeyService
                .createOrder(this.quantity)
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(() => {
                    this.matSnackBar.open('Gửi báo giá thành công!', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    this.quantity = null;
                    this.activationKeyService.getKeyHistory().then(() => {});
                });
        });
    }

    getPackageName(value: number) {
        if (value == 99) {
            return 'TRIAL99';
        }
        return value > 9999999 ? 'UNLIMITED' : 'MAX' + value;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}

export class FilesDataSource extends DataSource<any> {
    constructor(private _activationKeyService: ActivationKeyService) {
        super();
    }

    connect(): Observable<any[]> {
        return this._activationKeyService.onActivationKeysChanged;
    }

    disconnect(): void {}
}
