<div class="poster-component" fxLayout="column">
    <div class="action-bar" fxLayoutAlign="end start">
        <button mat-raised-button color="accent" (click)="addPoster()">
            <span> Thêm Poster </span>
        </button>
    </div>
    <div fxLayout="column">
        <div class="center pl-8 pr-8 poster-list-panel">
            <div [ngClass]="isPosterListSmallView ? 'small-view' : ''">
                <app-poster-list
                    [posterList]="posterList"
                    (selectedPosterEmitter)="selectPoster($event)"
                    [deletable]="true"
                    [editable]="true"
                    [selectable]="false"
                    (deletedPosterEmitter)="deletePoster($event)"
                    (editingPosterEmitter)="editPoster($event)"
                ></app-poster-list>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
