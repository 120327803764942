import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty, get } from 'lodash';
import { ConfirmationStatus } from '../../../../shared/constants/confirmationStatus';
import { RecruimentTypeEnum2LabelMapping } from '../../../../shared/models/campaign.model';

@Component({
    selector: 'app-campaign-result-cell',
    template: `
        <span class="avatar-container" *ngIf="type === 'avatar'">
            <img
                class="avatar-img"
                src="{{ candidate?.avatar }}"
                alt="{{ candidate?.fullName }}}"
            />
        </span>

        <span *ngIf="type === 'fullName'" class="name-cell">{{
            candidate.fullName
        }}</span>

        <span *ngIf="type === 'phone'">{{ candidate.phone }}</span>

        <span *ngIf="['round', 'age', 'health', 'exp'].includes(type)">{{
            convertedValue
        }}</span>

        <span *ngIf="type.includes('round')">{{ convertedValue }}</span>

        <span *ngIf="type === 'average'">{{ params.value?.toFixed(1) }}</span>

        <span *ngIf="type === 'recruitmentType'">{{ convertedValue }}</span>

        <span *ngIf="type === 'confirm'">
            <mat-icon *ngIf="params.value" class="active-icon green-600 s-16"
                >check
            </mat-icon>

            <mat-icon
                *ngIf="isDenied(params.data)"
                class="active-icon red-600 s-16"
                >close
            </mat-icon>
        </span>
    `,
    styleUrls: ['./campaign-result-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignResultCellRendererComponent
    implements ICellRendererAngularComp
{
    params!: ICellRendererParams;
    type = '';
    convertedValue = null;
    candidate = {
        avatar: '',
        fullName: '',
        birthday: 0,
        experiences: [],
        healthCertificate: {
            type: '',
        },
        phone: '',
    };

    agInit(params: ICellRendererParams) {
        this.params = params;
        this.type = params.colDef.field;

        if (!isEmpty(params.data.candidate)) {
            this.candidate = params.data.candidate;
        }

        if (this.type === 'recruitmentType') {
            this.convertedValue = RecruimentTypeEnum2LabelMapping[params.value];
        }

        if (this.type.includes('round')) {
            const roundIndex = get(params, 'roundIndex', -1);
            const results = get(params.data, 'roundResults', 0);
            if (roundIndex > -1) {
                this.convertedValue = results[roundIndex].score;
            }
        }

        if (this.type === 'age') {
            const { birthday } = this.candidate;
            this.convertedValue =
                new Date().getFullYear() -
                new Date(birthday).getFullYear() +
                '';
        }

        if (this.type === 'health') {
            const { healthCertificate } = this.candidate;
            this.convertedValue =
                isEmpty(healthCertificate) || isEmpty(healthCertificate.type)
                    ? ''
                    : healthCertificate.type;
        }

        if (this.type === 'exp') {
            const { experiences } = this.candidate;
            let year = 0;
            experiences.forEach((exp) => {
                year += exp.year + exp.month / 12;
            });

            this.convertedValue = Math.round(year * 10) / 10;
        }
    }

    isDenied(data): boolean {
        const { confirmationStatus } = data;
        return (
            confirmationStatus === ConfirmationStatus.deny ||
            confirmationStatus === ConfirmationStatus.backupDenied
        );
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
