import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
    Campaign,
    CampaignState,
    CandidateEvaluationEnum,
    CandidateFinalResult,
    GoToWorkNote,
    RecruitmentTypeEnum,
} from 'app/shared/models/campaign.model';
import { saveAs } from 'file-saver';
import { isEmpty, get } from 'lodash';
import * as moment from 'moment';
import { AuthService } from 'app/main/authentication/auth.service';
import { CampaignRequestService } from 'app/core/services/campaign-request.service';
import { User } from 'app/shared/models/user.model';
import { DatabaseService } from '../../../core/services/database.service';
import CampaignUtils from '../shared/campaign-utils';

@Injectable()
export class CampaignResultService implements Resolve<any> {
    onCandidatesChanged: BehaviorSubject<CandidateFinalResult[]>;
    onPaginatedDataChanged: BehaviorSubject<CandidateFinalResult[]>;
    onSelectedCandidatesChanged: BehaviorSubject<string[]>;
    onLoadingChanged: BehaviorSubject<boolean>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    currentCampaignId = '';
    currentCampaign: Campaign = undefined;
    currentResultStatus = '';
    candidates: CandidateFinalResult[];
    user: User;
    selectedCandidates: string[] = [];
    selectedRows = [];
    currentTableApi = null;
    searchText: string;
    viewingSize = 0;
    nextPage = 0;
    fetchedAll = false;
    isFetching = false;
    recruitmentTypeFilter: RecruitmentTypeEnum = null;
    statistic = {
        backup: 0,
        official: 0,
        confirmed: 0,
    };

    constructor(
        private _httpClient: HttpClient,
        private _campaignService: CampaignRequestService,
        private databaseService: DatabaseService,
        private _authService: AuthService
    ) {
        this.onCandidatesChanged = new BehaviorSubject([]);
        this.onPaginatedDataChanged = new BehaviorSubject([]);
        this.onSelectedCandidatesChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onLoadingChanged = new BehaviorSubject<boolean>(false);
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.candidates = [];
        this.onCandidatesChanged.next(this.candidates);

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCampaignById(route),
                this.databaseService.getTests(),
            ]).then(([files]) => {
                this.onSearchTextChanged.subscribe((searchText) => {
                    this.searchText = searchText;
                    this.getCampaignResults(route.params.id).then(() => {});
                });

                this.onFilterChanged.subscribe((filter) => {
                    this.recruitmentTypeFilter = filter;
                    this.getCampaignResults(route.params.id).then(() => {});
                });

                this.currentCampaignId = route.params.id;
                this.user = this._authService.getCurrentUser();

                resolve({});
            }, reject);
        });
    }

    getMoreResult() {
        return this.getCampaignResults(this.currentCampaignId, this.nextPage);
    }

    getCampaignResultStats(id) {
        this._httpClient
            .get(this.getFullUrl(`companies/campaigns/${id}/result/stats`))
            .subscribe((res: any) => {
                this.statistic = { ...res };
            });
    }

    getCampaignResults(id: any, page = 0): Promise<any> {
        if (this.isFetching) {
            return;
        }
        const removeDuplicateRound = (candidate) => {
            const { rounds } = candidate;
            try {
                candidate.rounds = rounds.filter(
                    (v, i, a) =>
                        a.findIndex((v2) => v2.round.id === v.round.id) === i
                );
            } catch (e) {
                console.log('error on filter round: ', { e });
            }
            return candidate.rounds;
        };

        if (page === 0) {
            this.fetchedAll = false;
            this.getCampaignResultStats(id);
            this.onLoadingChanged.next(true);
        }
        this.nextPage = page + 1;

        this.isFetching = true;
        return new Promise((resolve, reject) => {
            const params = new HttpParams()
                .set('page', page + '')
                .set('size', '20')
                .set('name', this.searchText || '');

            this._httpClient
                .get(this.getFullUrl(`companies/campaigns/${id}/result`), {
                    params,
                })
                .subscribe(
                    (response: any) => {
                        const { candidates, status } = response;

                        if (isEmpty(response)) {
                            this.fetchedAll = true;
                        }

                        candidates.forEach((c) => {
                            c.rounds = removeDuplicateRound(c);
                        });

                        const newData = candidates
                            .map((x) => new CandidateFinalResult(x))
                            .sort((c1, c2) => c1.order - c2.order);
                        if (page === 0) {
                            this.candidates = newData;
                        } else {
                            this.candidates = [...this.candidates, ...newData];
                        }

                        this.currentResultStatus = status;

                        if (this.recruitmentTypeFilter) {
                            this.candidates = this.candidates.filter(
                                (_candidate) =>
                                    _candidate.recruitmentType ===
                                    this.recruitmentTypeFilter
                            );
                        }

                        this.viewingSize = this.candidates.length;

                        this.onCandidatesChanged.next(this.candidates);
                        this.onPaginatedDataChanged.next(newData);

                        resolve(this.candidates);
                        this.onLoadingChanged.next(false);
                        this.isFetching = false;
                    },
                    () => {
                        this.currentResultStatus = '';
                        this.isFetching = false;
                        this.onLoadingChanged.next(false);
                    }
                );
        });
    }

    downloadCandidateData(inputCampaign: any = {}): Promise<any> {
        let campaignId = this.currentCampaignId;
        if (!isEmpty(inputCampaign)) {
            campaignId = inputCampaign.id;
        }
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    this.getFullUrl(
                        `companies/campaigns/${campaignId}/getNotifiedCandidateData`
                    ),
                    {
                        // responseType: 'arraybuffer'
                    }
                )
                .subscribe((response) => {
                    // this.downLoadFile(response, "application/octet-stream")
                    this.compileAndDownloadFile(response, inputCampaign);
                    resolve(response);
                }, reject);
        });
    }

    downloadCandidateImage(inputCampaign: any = {}): Observable<any> {
        let id = this.currentCampaignId;
        let jobTitle = get(this.currentCampaign, 'jobInfo.title', '');

        if (!isEmpty(inputCampaign)) {
            id = inputCampaign.id;
            jobTitle = get(inputCampaign, 'jobInfo.title', '');
        }

        return new Observable<any>((subscription) => {
            this._httpClient
                .get(this.getFullUrl(`companies/reports/${id}`), {
                    responseType: 'arraybuffer',
                })
                .subscribe(
                    (response) => {
                        const blob = new Blob([response], {
                            type: 'application/zip',
                        });
                        saveAs(blob, id + '_' + jobTitle + '.zip');
                        subscription.next(response);
                    },
                    (err) => {
                        console.log('Error while downloading image:', err);
                    }
                );
        });
    }

    compileAndDownloadFile(data: any, inputCampaign: any = {}) {
        const baseHeaders = [
            'firstName',
            'lastName',
            'gender',
            'birthday',
            'phone',
            'address',
            'town',
            'district',
            'province',
            'idCardNumber',
            'idCardDate',
            'idCardPlace',
            'healthType',
            'healthPlace',
            'certificates',
            'relationships',
            'experiences',
        ];
        const translatedHeaders = [
            'Tên',
            'Họ và tên đệm',
            'Giới tính',
            'Ngày sinh',
            'Số điện thoại',
            'Địa chỉ (HK)',
            'Phường (HK)',
            'TP/Quận/Huyện/TX (HK)',
            'Tỉnh/TP (HK)',
            'Số (CMND/CCCD)',
            'Ngày Cấp (CMND/CCCD)',
            'Nơi Cấp (CMND/CCCD)',
            'Sức khỏe loại',
            'Nơi cấp GKSK',
            'Bằng cấp (Loại bằng - Chuyên nghành - Trường - Ngày cấp)',
            'Thân nhân (Quan hệ - Họ và tên - Năm sinh)',
            'Kinh nghiệm làm việc (Công ty - Chức vụ - Thời gian làm việc)',
        ];
        const replacer = (key, value) => {
            if (value === null) {
                return '';
            }
            switch (key) {
                case 'birthday':
                case 'idCardDate':
                    const date = new Date(value);
                    return moment(date).format('DD/MM/YYYY');
                case 'phone':
                case 'address':
                case 'idCardNumber':
                    return "'" + value;
                default:
                    return value;
            }
        };
        // const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            baseHeaders
                .map((fieldName) =>
                    JSON.stringify(row[fieldName], () =>
                        replacer(fieldName, row[fieldName])
                    )
                )
                .join(',')
        );
        csv.unshift(translatedHeaders.join(','));
        const csvArray = csv.join('\r\n');

        const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvArray], {
            type: 'text/plain;charset=UTF-8',
        });

        let id = this.currentCampaignId;
        let jobTitle = get(this.currentCampaign, 'jobInfo.title', '');

        if (!isEmpty(inputCampaign)) {
            id = inputCampaign.id;
            jobTitle = get(inputCampaign, 'jobInfo.title', '');
        }

        saveAs(blob, `${id}_${jobTitle}.csv`);
    }

    getCampaignById(route: ActivatedRouteSnapshot): Promise<any> {
        return new Promise((resolve, reject) => {
            this._campaignService.getById(route.params.id).subscribe(
                (response) => {
                    this.currentCampaign = response.data;
                    resolve(response);
                },
                (err) => {
                    console.log('Cannot get campaign', err);
                    reject(err);
                }
            );
        });
    }

    toggleSelectedCandidate(id: string): void {
        if (this.selectedCandidates.length > 0) {
            const index = this.selectedCandidates.indexOf(id);

            if (index !== -1) {
                this.selectedCandidates.splice(index, 1);

                this.onSelectedCandidatesChanged.next(this.selectedCandidates);

                return;
            }
        }

        this.selectedCandidates.push(id);
        this.onSelectedCandidatesChanged.next(this.selectedCandidates);
    }

    toggleSelectAll(arr?: any[]): void {
        if (this.selectedCandidates.length === this.viewingSize) {
            this.deselectCandidates();
        } else {
            this.selectCandidates(arr);
        }
    }

    deselectCandidates(): void {
        this.selectedCandidates = [];
        this.selectedRows = [];
        this.onSelectedCandidatesChanged.next(this.selectedCandidates);
    }

    setViewingSize(size: number) {
        this.viewingSize =
            size < this.candidates.length ? size : this.candidates.length;
    }

    selectCandidates(arr?: any[]): void {
        console.log({ arr });
        this.selectedRows = arr;
        this.selectedCandidates = [];

        if (!this.viewingSize) {
            this.candidates.map((x) => {
                this.selectedCandidates.push(x.candidate.id.toString());
            });
        } else {
            arr.forEach((x) =>
                this.selectedCandidates.push(x.candidate.id.toString())
            );
        }

        this.onSelectedCandidatesChanged.next(this.selectedCandidates);
    }

    evaluateSelectedCandidates(
        candidateEvaluation: CandidateEvaluationEnum,
        recruitmentType: RecruitmentTypeEnum,
        callback: () => void
    ): void {
        const model = {
            backup: [],
            official: [],
            recruitLater: [],
            fail: [],
        };
        for (const candidateId of this.selectedCandidates) {
            const candidate = this.candidates.find(
                (_candidate) =>
                    _candidate.candidate.id.toString() ===
                    candidateId.toString()
            );
            const candidateIndex = this.candidates.indexOf(candidate);
            this.candidates[candidateIndex].result = candidateEvaluation;
            this.candidates[candidateIndex].recruitmentType = recruitmentType;
        }

        for (const candidate of this.candidates) {
            switch (candidate.recruitmentType) {
                case RecruitmentTypeEnum.IMMEDIATE:
                    model.official.push(candidate.candidate.id);
                    break;
                case RecruitmentTypeEnum.ADDITIONAL:
                    model.backup.push(candidate.candidate.id);
                    break;
                case RecruitmentTypeEnum.LATER:
                    model.recruitLater.push(candidate.candidate.id);
                    break;
                case RecruitmentTypeEnum.FAIL:
                    model.fail.push(candidate.candidate.id);
                    break;
            }
        }

        this._httpClient
            .put(
                this.getFullUrl(
                    `companies/campaigns/${this.currentCampaignId}/result`
                ),
                model
            )
            .subscribe(() => {
                this.selectedRows.forEach(
                    (row) => (row.recruitmentType = recruitmentType)
                );
                this.currentTableApi.applyTransaction({
                    update: this.selectedRows,
                });
                this.getCampaignResultStats(this.currentCampaignId);
                this.currentTableApi.redrawRows();
                callback();
            });

        this.onCandidatesChanged.next(this.candidates);
        this.deselectCandidates();
    }

    shipCampaignResult(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/result/ship`
                    ),
                    {}
                )
                .subscribe((response: any) => {
                    this.getCampaignResults(this.currentCampaignId);
                    resolve(response);
                }, reject);
        });
    }

    submitCampaignResult(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/result/submit`
                    ),
                    data
                )
                .subscribe((response: any) => {
                    this.getCampaignResults(this.currentCampaignId);
                    resolve(response);
                }, reject);
        });
    }

    updateCandidateFinalRoundOrder(data = []): Promise<any> {
        const filteredData = data.filter((d) => !isEmpty(d.type));
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/candidates/sort`
                    ),
                    filteredData
                )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateCandidateRoundOrder(roundId: number, data = []): Promise<any> {
        const filteredData = data.filter((d) => !isEmpty(d.type));
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/rounds/${roundId}/candidates/sort`
                    ),
                    filteredData
                )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    approveCampaignResult(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/result/approve`
                    ),
                    {}
                )
                .subscribe((response: any) => {
                    this.getCampaignResults(this.currentCampaignId);
                    resolve(response);
                }, reject);
        });
    }

    notifyCampaignResult(goToWorkNote: GoToWorkNote): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/result/notify`
                    ),
                    goToWorkNote
                )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    rejectCampaignResult(feedBack: string): Promise<any> {
        const body = feedBack && feedBack.length ? feedBack : 'none';
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    this.getFullUrl(
                        `companies/campaigns/${this.currentCampaignId}/result/reject`
                    ),
                    body
                )
                .subscribe((response: any) => {
                    this.getCampaignResults(this.currentCampaignId).then(() => {
                        resolve(response);
                    });
                }, reject);
        });
    }

    resetCampaignResult() {
        this.getCampaignResults(this.currentCampaignId);
    }

    getFullUrl(url): string {
        return environment.apiEndpoint + url;
    }

    isInManagingState(): boolean {
        return (
            this.hasNoStatus() ||
            this.currentResultStatus === CampaignState.NOT_READY ||
            this.currentResultStatus === CampaignState.REJECTED
        );
    }

    isManagingResult(): boolean {
        return this.isInManagingState() && this.isInAdminGroup();
    }

    hasNoStatus(): boolean {
        return !this.currentResultStatus || !this.currentResultStatus.length;
    }

    isOwner(): boolean {
        return this.user.identity === this.currentCampaign.ownerId;
    }

    isInAdminGroup(): boolean {
        const userId = this._authService.getCurrentUserId();
        const {
            ownerId = -1,
            approverId = -1,
            reviewerId = -1,
        } = this.currentCampaign;
        return (
            userId !== -1 && [ownerId, approverId, reviewerId].includes(userId)
        );
    }

    sortCandidates(criteria = [], callback = () => {}): void {
        if (isEmpty(criteria)) {
            return null;
        }

        this.candidates = CampaignUtils.sortByCustomCriteria(
            this.candidates,
            criteria
        );

        const data = {
            candidateOrder: [],
        };

        this.candidates.forEach((c, i) => {
            c.order = i + 1;
            data.candidateOrder.push(c.candidate.id);
        });

        this.updateCandidateFinalRoundOrder(criteria)
            .then(() => {
                callback && callback();
            })
            .catch((err) => {
                console.log('Cannot update candidate order', err);
            });
    }

    getCounts() {
        return {
            countAccept: this.statistic.confirmed,
            countOfficial: this.statistic.official,
            countBackup: this.statistic.backup,
        };
    }

    checkHasOrder() {
        if (isEmpty(this.candidates)) {
            return false;
        }
        return !this.candidates.some((c) => c.order === null);
    }
}
