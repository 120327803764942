import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate() {
        if (this.authService && this.authService.isAuthenticated()) {
            this.router.navigate(['/campaigns']).then(() => {});
            return false;
        } else {
            this.authService.logout();
            return true;
        }
    }
}
